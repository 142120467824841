.navbar__search {
    position: relative;
    color: #aaa;
    font-size: 16px;
    width: 70%;
  }
  
  .navbar__search {
    display: inline-block;
  }
  
  .search_course {
    width: 100%;
    height: 45px;
    background: #fcfcfc;
    border: 1px solid #aaa;
    text-indent: 32px;
    border-radius: 85px;
  }
  
  .navbar__search span {
    position: absolute;
    top: 10px;
    font-size: 20px;
    right: 10px;
  }
  .search_btn {
    position: absolute;
    top: 3px;
    left: 4px;
    font-size: 17px;
   /* padding-left: 15px; */
   padding-right: 15px;
    padding-bottom: 30px;
    height: 35px;
    width: 80px;
  }
 
  
  /* .search_dropdown {
    list-style-type: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    position: absolute; 
    top: 100%;
    left: 0;
    width: 100%; 
    z-index: 999; 
  } */
  
  /* .search_dropdown li {
    padding: 8px;
    cursor: pointer;
  }
  
  .search_dropdown li:hover {
    background-color: #f5f5f5;
  } */
  
  /* .navbar__search ul li img {
    width: 50px;
    border-radius: 50%;
    margin: 10px 24px;
  } */
  .search_blog{
    width: 100%;
  height: 45px;
  background: #fcfcfc;
  border: 1px solid #aaa;
  text-indent: 32px;
  border-radius: 85px;
}
  .search_btn_blog{
    position: absolute;
    background-color: #833988;
    top: 3px;
    left: 4px;
    font-size: 17px;
    border-radius: 15px;
    color: #fff;
   /* padding-left: 15px; */
   padding-right: 15px;
    padding-bottom: 30px;
    height: 35px;
    width: 80px;
  }
  .search_btn_blog:hover{
    background-color: #F57D20;
    color: #fff;
  }
/* Table */
.table_head_cardprice {
    --bs-table-bg: #833988;
    color: #fff;
  }
  .desc_table_cardprice {
    --bs-table-color: #fff;
  } 
  .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  /* End Table */
  @media screen and (max-width:768px) {
    .navbar__search {
        width: 100%;    }
        /* .search_blog_cont{
          width: 43%;
        }
        .faq__search{
          width: 100%;    } */
          
       
          
  }
  