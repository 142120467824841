.App {
  display: grid;
  grid-template-columns: 50px 1fr; 
  gap: 20px; }
  
/* 
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh; 
  width: 200px; 
  background-color: #f0f0f0;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

.main-content {
  padding: 20px;
   overflow-x: auto;
}

