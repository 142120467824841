.title_add_course{
    background-color: #F57D20;
    color: #fff;
    width: 200px;
    text-align: center;
    padding: 15px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    margin-top: 10vh;

}
.input_title_addcourse{
    color: #833988;
    font-size: 15px;
line-height:45px;
/* margin-top: 10vh; */

}
.input_addcourse{
    border-radius: 20px;
    height: 45px;
    width: 100%;
    border: 1px solid #a3a3a3;
padding-right:15px;
    }
.select_dep{
    height: 45px;
    width: 80%;
}
.input_textarea_addcourse{
    border-radius: 20px;
    height: 90%;
    width: 100%;
    border: 1px solid #a3a3a3;
padding-right:15px;
}


.file-input-container {
    position: relative;
    width: 80%; 
    height: 40px; 
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
  }
  
  .choose_file_addcourse {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  .cont_addvideo{
    width: 100%;
    border: dotted 1px #833988;
  }
  .file_input_addvideo{
    position: relative;
    width: 100%; 
    height: 50px; 
    border: 1px dotted #833988;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
  }
  .selected_file_addvideo{
    text-align: center;
  }
 
  .btn_choose_video{
    background-color: #fff;
    color: #833988;
    border: #833988 1px dotted;
  }
  .btn_add_video{
    background-color: #F57D20;
    border-radius: 15px;
    color: #fff;
    margin-top: 3vh;
 }
 .btn_addCourse{
  background-color: #833988;
  border-radius: 15px;
  color: #fff;
  border: none;
  position: relative;
  padding-right: 3rem; 
 }
  .btn_addCourse:hover{
    background-color: #F57D20;
  }
 
 @media screen and (max-width: 768px) {
    .selected_file_addvideo {
      display: none;
    }
    /* .selected_file_addcourse{
      padding-top: 2vh;
      padding-bottom: 2vh;
    } */
    .btn_addCourse{
      margin-top: 10vh;
    }
    .input_title_addcourse{
      margin-top: 10vh;

    }
    .title_add_course{
      margin-top: 25vh;
    }
  }