.login_cont{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.box_purple_auth {
    background-color: #833988;

    border-radius: 10px;
  }
  .logo_auth {
    height: 20%;
    width: 20%;
  }
  .hello_logo_auth_cont {
    margin-bottom: 189px;
  }
  .hi_auth {
    color: #f57d20;
    padding-top: 2vh;
  }
  .auth_btn {
    background-color: #fff;
    color: #833988;
    border-radius: 15px;
    margin: 1vh;
  }
  .auth_btn:hover {
    background-color: #a767ac;
    color: #fff;
  }
  .have_account_auth {
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    padding-bottom: 2vh;
  }
  .have_account_here_auth {
    color: #f57d20;
    font-size: 13px;
    font-weight: 700;
  }
  .have_account_here_auth:hover {
    text-decoration: underline;
  }
  .cont_input_auth {
    background-color: #f0f2f5;
    border-radius: 10px;
  }
  .title_of_input_auth {
    color: #833988;
    padding-top: 1vh;
    padding-right: 1vh;
    text-align: right;
  }
  .forget_pass_auth {
    color: #833988;
    font-size: 13px;
    font-weight: 700;
    text-align: left;
    padding-top: 1vh;
    text-decoration: none;
  }
  .forget_pass_auth:hover {
    text-decoration: underline;
  }
  /* profile */
  .profile_title{
      background-color: #f57d20;
      color: #fff;
      border-radius: 10px;
      padding: 2vh;
      font-size: 30px;
      font-weight: 700;
  
  }
  .img_profile{
      border-radius: 50px;
      height: 60%;
      width: 60%;
      object-fit: cover;
      border: none;
      background-color: #fff;
  }
  
  .error_message {
    color: red;
    font-size: 0.9em;
    margin-top: 5px;
  }
  
  
  /* Style for input fields with errors */
  .search_blog.error {
    border-color: red;
    border-width: 2px;
  }
  
  
  
  .error_input {
    border-color: red;
  }
  .select_role_auth{
    width: 50%;
    margin-top: 5vh;
    border-radius: 20px;
    height: 65%;
  }
  .purple_btn {
    background-color: #833988;
    color: #fff;
    border-radius: 15px;
    margin-left: 5px;
  }
  .purple_btn:hover{
    background-color: #a767ac;
  }
  @media screen and (max-width: 992px) {
    .select_role_auth{
      width: 100%;
    
      height: 60%;
    }
    .login_cont{
      height: auto;
    }
    
  }
  